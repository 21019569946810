import Layout from "components/layout/Layout";
import { PageHeader } from "components/PageHeader/PageHeader";
import { SEO } from "components/SEO";
import React from "react";

import "assets/styles/pages/disclaimer-page.scss";

const Disclaimer: React.FC = () => {
  return (
    <Layout>
      <SEO title="Disclaimer" />

      <PageHeader title="Disclaimer" />

      <div className="disclaimer-page" role="presentation">
        <section className="content__wrapper container">
          <p>
            This project has been funded with support from the European
            Commission. This website reflects the views only of the author, and
            the Commission cannot be held responsible for any use which may be
            made of the information contained therein.
          </p>
        </section>
      </div>
    </Layout>
  );
};

export default Disclaimer;
